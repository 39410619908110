import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const caj = [
  {
    item_uuid: uuid(),
    title: "Čaj porciovaný",
    descr: "/&nbsp;podľa ponuky&nbsp;/",
    volume: "0,3 l",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mätový čaj",
    descr: "",
    volume: "0,5 l",
    price: "3,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zázvorový čaj",
    descr: "",
    volume: "0,5 l",
    price: "3,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mätovo-zázvorový čaj",
    descr: "",
    volume: "0,5 l",
    price: "3,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Čaj sypaný Eilles",
    descr: "/&nbsp;ovocný, pepermintový, Earl Grey, harmančekový&nbsp;/",
    volume: "0,5 l",
    price: "3,30 €",
    divideAfter: true
  },
 /*  {
    item_uuid: uuid(),
    title: "Čierny čaj s rumom",
    descr: "/&nbsp;Havana Club Añejo Especial&nbsp;/",
    volume: "0,3 l",
    price: "2,50 €",
    divideAfter: true
  }, */
  {
    item_uuid: uuid(),
    title: "Med",
    descr: "",
    volume: "20 g",
    price: "0,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Citrón",
    descr: "1 plátok",
    volume: "",
    price: "0,20 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: true
  },
]