import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const ladove_kremy_a_frappe = [
  {
    item_uuid: uuid(),
    title: "Vanilkový kokteil",
    descr: "15 cl mlieko, 30 g vanilková zmrzlina, 20 g šľahačka",
    volume: "",
    price: "3,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ľadové frappé - jahoda",
    descr: "25 g frappé, 15 cl mlieko, ľad",
    volume: "",
    price: "3,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ľadové frappé - banán",
    descr: "25 g frappé, 15 cl mlieko, ľad",
    volume: "",
    price: "3,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mascarpone krém - mango",
    descr: "25 g mascarpone, 15 cl mlieko, ľad",
    volume: "",
    price: "3,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mascarpone krém - mandarínka",
    descr: "25 g mascarpone, 15 cl mlieko, ľad",
    volume: "",
    price: "3,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Fresh yogurt krém - rebarbora",
    descr: "25 g fresh yogurt, 15 cl mlieko, ľad",
    volume: "",
    price: "3,30 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Fresh yogurt krém - kiwi",
    descr: "25 g fresh yogurt, 15 cl mlieko, ľad",
    volume: "",
    price: "3,30 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },

]

