import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"
export const pivo = [
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Fľašové Pivo - Podľa ponuky",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Krušovice",
    descr: "10°",
    volume: "0,50 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Krušovice",
    descr: "12°",
    volume: "0,50 l",
    price: "2,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zlatý Bažant Čierne",
    descr: "10°",
    volume: "0,50 l",
    price: "2,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zlatý Bažant - nealko",
    descr: "",
    volume: "0,50 l",
    price: "2,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zlatý Bažant - Radler Citrón",
    descr: "",
    volume: "0,50 l",
    price: "2,40 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Čapované Pivo",
    descr: "Podľa ponuky",
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: true,
    lastElement: false
  },
  /* {
    item_uuid: uuid(),
    title: "Krušovice Bohem",
    descr: "11°",
    volume: "0,30 l / 0,50 l",
    price: "1,40 € / 2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zlatý Bažant - Radler Citrón",
    descr: "",
    volume: "0,30 l / 0,50 l",
    price: "1,20 € / 2,00 €",
    divideAfter: true
  }, */
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]