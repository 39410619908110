import PrednaHoraPageElement from "./PrednaHoraPageElement"
import { prednaHoraConfig } from "../../../config/clients/prednaHora"
import { prednaHoraMenu } from "../../../content/PrednaHora/menu"
import { useEffect, useState } from "react"
import ClientContact from "../global/ClientContact"

import {
  BiHide
} from "react-icons/bi"
//@ts-ignore
import logo from '../../../assets/images/clients/logos/christmas/predna_hora_christmas.png'
//@ts-ignore
/* import logo from '../../../assets/images/clients/logos/predna_hora.jpg' */
import UpdatingMenuCard from "../../cards/UpdatingMenuCard"
import SeasonalAnimationWrap from "../../seasonalAnimations/SeasonalAnimationWrap"
import LoadingScreen from "../../cards/LoadingScreen"
//@ts-ignore
/* import logo from '../../../assets/images/clients/logos/halloween/predna_hora_halloween.png' */

const PrednaHora = () => {

  const localConfig = prednaHoraConfig
  const localMenu = prednaHoraMenu
  const CloseAllIcon = localConfig.hideAllIcon || BiHide



  const [menu, updateMenu] = useState(localMenu)
  const [showLoadingScreen, setShowLoadingScreen] = useState(true)
  const [hideAllButtonVisible, setHideAllButtonVisible] = useState(false)
  const [previousPageUUID, setPreviousPageUUID] = useState("")
  const [rows, setRows] = useState(menu.map((row, index) => {
    return {
      isOpen: false,
      rowIndex: index
    }
  }))


  const handleOpening = (isOpen: boolean, rowIndex: number) => {
    const updatedRows = rows
    rows.forEach((row, index) => {
      if (index === rowIndex) {
        row.isOpen = !isOpen
      }
    })
    setRows(updatedRows)
    return !isOpen
  }

  const updateIsOpen = (array: any[], page_uuid: string, rowIndex: number) => {
    array.forEach(item => {
      if (Array.isArray(item)) {
        updateIsOpen(item, page_uuid, rowIndex);
      } else if (item.page_uuid === page_uuid) {
        item.isOpen = handleOpening(item.isOpen, rowIndex);
      } else if (item.page_uuid !== page_uuid && localConfig.closePreviousSectionOn) {
        item.isOpen = false
      }
    });

    return array;
  };

  const closeAll = (array: any[]) => {
    array.forEach(item => {
      if (Array.isArray(item)) {
        closeAll(item);
      } else {
        item.isOpen = false
      }
    });

    return array;
  };

  const isAnyOpen = () => {
    return rows.find(row => row.isOpen)
  }

  const handleClosePage = (current_page_uuid: string, rowIndex: number, blockIndex: number) => {
    const updatedArray = updateIsOpen([...menu], current_page_uuid, rowIndex)
    updateMenu(updatedArray)
    if (previousPageUUID === current_page_uuid && !rows[rowIndex].isOpen) {
      setHideAllButtonVisible(false)
      setTimeout(() => {
        window.scrollTo({
          behavior: 'smooth',
          top: 0
        })
      }, 300);
    } else {
      setHideAllButtonVisible(true)
      if (localConfig.scrollToTopAferOpen) {
        setTimeout(() => {
          window.scrollTo({
            behavior: 'smooth',
            top: 0
          })
        }, 100);
      }
      if (localConfig.autoscrollAfterClick) {

        setTimeout(() => {
          const element = document.getElementById(menu[rowIndex].find(page => page.page_uuid === current_page_uuid)!.id)!
          element.scrollIntoView({
            behavior: 'smooth'
          })
          setTimeout(() => {
            if (localConfig.scrollToTopAfterClose) {
              if (!isAnyOpen()) {
                window.scrollTo({
                  behavior: 'smooth',
                  top: 0
                })
              }
            }
          }, 300);
        }, localConfig.autoscrollTimeInMs);


      }
    }
    setPreviousPageUUID(current_page_uuid)

  }
  const isAtLeastOneBlockOpen = (row: any) => {
    //@ts-ignore
    return row.some(el => el.isOpen)
  }

  const handleCloseAll = () => {
    const updatedArray = closeAll([...menu])
    updateMenu(updatedArray)
    setHideAllButtonVisible(false)
    setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        top: 0
      })
    }, 300)
  }


  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
  useEffect(() => {
    document.body.style.background = "white"
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  },[])


  useEffect(() => {
    document.body.style.overflow = "hidden"
    setTimeout(() => {
      document.body.style.overflow = "visible"
    }, 1200)
    setTimeout(() => {
      setShowLoadingScreen(false)
    }, 1500)
  },[])

  return (
    <div
      className="prednaHora"
    >
     {/*  <HalloweenAnimation /> */}
     {/* <WinterAnimation /> */}
      {
        showLoadingScreen &&
        <LoadingScreen />
      }
      <SeasonalAnimationWrap />

      <div className="backgroundImageTop" />
      <div className="logo_wrap">
        <img
          className="logo"
          src={logo}
          alt="Piváreň Predná Hora Humenné"
        />
      </div>
      {
        !localConfig.menuUpdating ?
        <>
          <div
            className={`main_content_wrap ${(!hideAllButtonVisible && localConfig.displayAllOnPhone) ? "marginTopLocalAdjustment" : ""}`}
            id="DRAG_SCROLL"
          >
            {menu.map((row: any, rowIndex: number) => <div
              className="row"
              key={rowIndex}
              style={{
                flexDirection: isAtLeastOneBlockOpen(row) ? "column" : "row"
              }}
            >
              {row.map((page: any, blockIndex: number) =>
              <PrednaHoraPageElement
                id={page.id}
                icon={page.icon}
                iconWidth={page.iconWidth}
                key={blockIndex}
                page_uuid={page.page_uuid}
                title={isMobileDevice() ? page.titleShort : page.title}
                rowIndex={rowIndex}
                blockIndex={blockIndex}
                list={page.list}
                isOpen={page.isOpen}
                blockBackground={page.blockBackground}
                closePage={handleClosePage}
                style={{
                  display: (!page.isOpen && hideAllButtonVisible && localConfig.hideAllWhenOneOpen) ? "none" : "flex",
                }}
                localConfig={localConfig} />
              )}
            </div>
            )}
          </div>
          <div
            className="prednaHora_open_close_all_button"
            style={{
              transform: hideAllButtonVisible ? "translateX(0)" : "translateX(100px)"
            }}
          >
              <CloseAllIcon
                size={"40px"}
                onClick={() => handleCloseAll()} />
            </div>
          </>
        : <UpdatingMenuCard />

      }

      <ClientContact
        config={localConfig}
      />
       {/*
       menu.map((page: any, index: number) =>
            <PrednaHoraPageElement
              id={page.id}
              key={index}
              page_uuid={page.page_uuid}
              title={page.title}
              list={page.list}
              isOpen={page.isOpen}
              closePage={handleClosePage}
              backgroundColorStyle={page.backgroundColorStyle}
            />
          )
           */}
      {/* <div className="backgroundImageBottom"/> */}
    </div>
  )
}

export default PrednaHora