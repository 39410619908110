//@ts-nocheck
import uuid from "react-uuid"

import leafIcon from "../../assets/images/clients/icons/panorama/leafIcon.svg"
/* import spreadIcon from "../../assets/images/clients/icons/just_lovely_cafe/spread.svg" */
import coffeeIcon from "../../assets/images/clients/icons/panorama/coffee-cup.svg"
import teaIcon from "../../assets/images/clients/icons/panorama/tea.svg"
/* import feastIcon from "../../assets/images/clients/icons/panorama/feast.svg" */
import nonalcoIcon from "../../assets/images/clients/icons/panorama/drink.svg"
import beerIcon from "../../assets/images/clients/icons/panorama/beer.svg"
import snacksIcon from "../../assets/images/clients/icons/panorama/snack.svg"
import wineIcon from "../../assets/images/clients/icons/panorama/glass-with-wine.svg"
import specialginIcon from "../../assets/images/clients/icons/panorama/ginspecial.svg"
import alcoIcon from "../../assets/images/clients/icons/panorama/shots.svg"
/* import hotChocolateIcon from "../../assets/images/clients/icons/jazz_cafe/hot_chocolate.svg" */


/* import { horucaCokolada } from "./elements/horucaCokolada" */
import { kava } from "./elements/kava"
import { caj } from "./elements/caj"
import { jesenneMenu } from "./elements/jesenneMenu"
/* import { domace_natierky } from "./elements/domace_natierky" */
import { limonady } from "./elements/limonady"
import { alkohol } from "./elements/alkohol"
import { nealko } from "./elements/nealko"
import { vino } from "./elements/vino"
import { pivo } from "./elements/pivo"
import { snack } from "./elements/snack"
/* import { feast } from "./elements/feast" */





export const justLovelyCafeMenu =  [
  [
    {
      id: "HODY_ID",
      icon: leafIcon,
      iconWidth: "60px",
      page_uuid: uuid(),
      titleShort: "Jesenné menu",
      title: "Jesenné menu",
      list: jesenneMenu,
      isOpen: false,
    },
  ],
  [
    {
      id: "KAVA_ID",
      icon: coffeeIcon,
      iconWidth: "50px",
      iconMarginRight: "-8px",
      page_uuid: uuid(),
      titleShort: "Káva",
      title: "Káva",
      list: kava,
      isOpen: false,
    },
    /* {
      id: "NATIERKY_ID",
      icon: spreadIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Domáce nátierky",
      title: "Domáce nátierky",
      list: domace_natierky,
      isOpen: false,
    }, */
  ],
  [
    {
      id: "CAJ_ID",
      icon: teaIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Čaj & čokoláda",
      title: "Čaj & čokoláda",
      list: caj,
      isOpen: false,
    },

  ],
  [
    {
      id: "LIMONADY_ID",
      icon: specialginIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Limonády",
      title: "Limonády",
      list: limonady,
      isOpen: false,
    },
    {
      id: "ALKOHOL_ID",
      icon: alcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Alkohol",
      title: "Alkohol",
      list: alkohol,
      isOpen: false,
    },
  ],
  [
    {
      id: "NEALKO_ID",
      icon: nonalcoIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Nealkoholické nápoje",
      title: "Nealkoholické nápoje",
      list: nealko,
      isOpen: false,
    },

  ],
  [
    {
      id: "VINO_ID",
      icon: wineIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Víno",
      title: "Víno",
      list: vino,
      isOpen: false,
    },
    {
      id: "PIVO_ID",
      icon: beerIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Pivo",
      title: "Pivo",
      list: pivo,
      isOpen: false,
    },
  ],
  [
    {
      id: "SNACKY_A_POCHUTINY_ID",
      icon: snacksIcon,
      iconWidth: "50px",
      page_uuid: uuid(),
      titleShort: "Snacky & pochutiny",
      title: "Snacky & pochutiny",
      list: snack,
      isOpen: false,
    },
  ]
]

