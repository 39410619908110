import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

import chocolateIcon from "../../../assets/images/clients/icons/obyvacka_bar/winterDrink.svg"
import hotDrinkIcon from "../../../assets/images/clients/icons/panorama/coffee-cup.svg"
import puncIcon from "../../../assets/images/clients/icons/panorama/coffee-cup.svg"

import recommended_flag from "../../../assets/images/clients/icons/panorama/recommended_flag.png"

const generateImage = (icon, width) => {
  /* return () => <img src={icon} style={{width: width}} /> */
  return null
}

export const zimnaPonuka = [
  {
    type: "extra",
    icon: generateImage(chocolateIcon, "35px"),
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Káva a iné",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Horúca čokoláda",
    descr: "/&nbsp;podľa ponuky&nbsp;/",
    volume: "60 g",
    price: "3,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Horúca čokoláda s lesným ovocím",
    descr: "/&nbsp;podľa ponuky&nbsp;/",
    volume: "60 g",
    price: "3,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Hot Chai Latte",
    descr: "",
    volume: "0,2 l",
    price: "2,30 €",
    flagBehindTitle: true,
    flag: recommended_flag,
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pumpkin Spice Latte",
    descr: "",
    volume: "0,2 l",
    price: "2,60 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: generateImage(hotDrinkIcon, "35px"),
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Horúce drinky",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Hot Apricot",
    descr: "/&nbsp;horúca marhuľovo-zázvorová limonáda&nbsp;/",
    volume: "240 ml",
    price: "3,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Hot Apricot *alkoholická verzia",
    descr: "/&nbsp;horúca marhuľovo-zázvorová limonáda + Malfy Gin Con Limone&nbsp;/",
    volume: "280 ml",
    price: "4,00 €",
    flagBehindTitle: true,
    flag: recommended_flag,
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Hot Cherry",
    descr: "/&nbsp;horúca višňovo-škoricová limonáda&nbsp;/",
    volume: "240 ml",
    price: "3,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Hot Cherry  *alkoholická verzia",
    descr: "/&nbsp;horúca višňovo-škoricová limonáda + Havana Club Añejo Especial&nbsp;/",
    volume: "280 ml",
    price: "3,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: generateImage(puncIcon, "35px"),
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Punč",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Vianočný punč",
    descr: "/&nbsp;Becherovka, červené víno&nbsp;/",
    volume: "240 ml",
    price: "3,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Panorama punč",
    descr: "/&nbsp;Lúčky Gin, biele víno&nbsp;/",
    volume: "240 ml",
    price: "3,90 €",
    flagBehindTitle: true,
    flag: recommended_flag,
    divideAfter: true
  },
  {
    type: "extra",
    icon: generateImage(puncIcon, "35px"),
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Varené víno",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Varené víno červené",
    descr: "",
    volume: "240 ml",
    price: "3,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Varené víno biele",
    descr: "",
    volume: "240 ml",
    price: "3,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: true
  },
]