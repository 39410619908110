/* import HalloweenAnimation from "./HalloweenAnimation" */
import WinterAnimation from "./WinterAnimation"
/* import AutumnAnimation from "./AutumnAnimation" */

const SeasonalAnimationWrap = () => {
  return (
    <>
      <WinterAnimation />
      {/* <HalloweenAnimation /> */}
      {/* <AutumnAnimation /> */}
    </>
  )
}

export default SeasonalAnimationWrap