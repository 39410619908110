import uuid from "react-uuid"
import vegan_flag from "../../../assets/images/clients/icons/just_lovely_cafe/vegan_flag.png"
import hummus_flag from "../../../assets/images/clients/icons/just_lovely_cafe/hummus_flag.png"
import meat_flag from "../../../assets/images/clients/icons/just_lovely_cafe/meat_flag.png"

export const domace_natierky = [
  {
    type:"extra",
    item_uuid: uuid(),
    title: "Domáce nátierky",
    descr: "Všetky nátierky sú podávané s čerstvým chlebíkom a zeleninovým prílohovým šalátikom ktorý ma 50g",
    volume: "",
    price: "",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Mrkvovo - šošovicová",
    descr: "",
    volume: "75 g",
    price: "5,50 €",
    flagBehindTitle: false,
    flag: vegan_flag,
    flagHeight: "20px",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Červená repa",
    descr: "",
    volume: "75 g",
    price: "5,50 €",
    flagBehindTitle: false,
    flag: vegan_flag,
    flagHeight: "20px",
    divideAfter: false
  },
  {
    type: "image",
    item_uuid: uuid(),
    offerImg: require("../../../assets/images/clients/items/JustLovelyCafe/domace_natierky.jpg"),
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Hummus so sušenými paradajkami",
    descr: "",
    volume: "75 g",
    price: "5,90 €",
    flagBehindTitle: false,
    flag: hummus_flag,
    flagHeight: "20px",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Cícerový hummus klasik",
    descr: "",
    volume: "75 g",
    price: "5,90 €",
    flagBehindTitle: false,
    flag: hummus_flag,
    flagHeight: "20px",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Tekvicový hummus",
    descr: "",
    volume: "75 g",
    price: "5,90 €",
    flagBehindTitle: false,
    flag: hummus_flag,
    flagHeight: "20px",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Paštéta s jelením mäsom a brusnicami",
    descr: "",
    volume: "75 g",
    price: "6,90 €",
    flagBehindTitle: false,
    flag: meat_flag,
    flagHeight: "20px",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Paštéta s kuracím mäsom",
    descr: "",
    volume: "75 g",
    price: "6,90 €",
    flagBehindTitle: false,
    flag: meat_flag,
    flagHeight: "20px",
    divideAfter: true
  },
]