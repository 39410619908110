import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const caj = [
  {
    item_uuid: uuid(),
    title: "English Tea Shop",
    descr: "1,5 g - 2,5 g, rez citróna",
    volume: "",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Čaj z čerstvého zázvoru",
    descr: "",
    volume: "0,35 l / 0,7 l",
    price: "2,90 € / 4,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Čaj z čerstvej mäty",
    descr: "",
    volume: "0,35 l / 0,7 l",
    price: "2,90 € / 4,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Doplnky",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "smotana Maresi",
    descr: "",
    volume: "1 cl",
    price: "0,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "šľahačka",
    descr: "",
    volume: "20 g",
    price: "0,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "med",
    descr: "",
    volume: "6 g",
    price: "0,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]