import { IoMdArrowRoundBack } from "react-icons/io"
import { mercyCoffeeMenu } from "../../content/MercyCoffee/menu"

export const mercyCoffeeConfig = {
  isRestaurant: false,
  menu: mercyCoffeeMenu,
  logo: require("../../assets/images/clients/logos/mercy_coffee.png"),
  /* logo: require("../../assets/images/clients/logos/halloween/mercy_coffee_halloween.png"), */


  // after click on section screen scrolls to that section
  autoscrollAfterClick: false,
  scrollToTopAferOpen: true,
  // left alignment if displayed in rows
  alignTitleToLeft: false,
  //scroll time of scroll to section
  autoscrollTimeInMs: 100,
  //scroll to top of page after closing section
  scrollToTopAfterClose: true,
  //icon that appears in corner when section is opened
  hideAllIcon: IoMdArrowRoundBack,
  //decides if other sections should dissapear when section is opened
  hideAllWhenOneOpen: true,
  // close previous section when new section is opened
  closePreviousSectionOn: true,
  //margin top to display all sections
  displayAllOnPhone: false,
  //contact icon color
  iconColor: "#f1e3d0",
  //links to social sites
  facebook: "https://www.facebook.com/profile.php?id=100063554531875",
  instagram: "https://www.instagram.com/mercycoffee_sk/",
  email: null,
  phone: "+421 951 947 324",

  //styles
  mainWrapClass: "mercyCoffee",
  bottomNote: ""
  /*
  logoBorderRadius: "8px",
  background: "#CFCAC4",
  blockBackgroundColor: "#878787",
  dividerColor: "#F7C808", */


}
