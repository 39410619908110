import { IoMdArrowRoundBack } from "react-icons/io"
import { obyvackaBarMenu } from "../../content/ObyvackaBarHumenne/menu"

export const obyvackaBarConfig = {
  isRestaurant: false,
  menuUpdating: false,
  menu: obyvackaBarMenu,
  /* default logo './assets/images/clients/logos/obyvacka_bar.png' */
  /* logo: require("../../assets/images/clients/logos/obyvacka_bar.png"), */
  /* logo: require("../../assets/images/clients/logos/halloween/obyvacka_bar_halloween.png"), */
  logo: require("../../assets/images/clients/logos/christmas/obyvacka_bar_christmas.png"),

  // after click on section screen scrolls to that section
  autoscrollAfterClick: false,
  scrollToTopAferOpen: true,
  // left alignment if displayed in rows
  alignTitleToLeft: false,
  //scroll time of scroll to section
  autoscrollTimeInMs: 100,
  //scroll to top of page after closing section
  scrollToTopAfterClose: true,
  //icon that appears in corner when section is opened
  hideAllIcon: IoMdArrowRoundBack,
  //decides if other sections should dissapear when section is opened
  hideAllWhenOneOpen: true,
  //vibrate on click on section
  vibrateOnClick: false,
  // close previous section when new section is opened
  closePreviousSectionOn: true,
  //margin top to display all sections
  displayAllOnPhone: false,
  //contact icon color
  iconColor: "#C5D92D",
  //links to social sites
  facebook: "https://www.facebook.com/ObyvackaBarHumenne",
  instagram: "https://www.instagram.com/obyvackabar_humenne/",
  email: "playbarkosice@gmail.com",
  phone: "+421 910 202 676",

  //styles
  mainWrapClass: "obyvackaBar",
  bottomNote: ""
  /*
  logoBorderRadius: "8px",
  background: "#CFCAC4",
  blockBackgroundColor: "#878787",
  dividerColor: "#F7C808", */


}
