import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const domace_limonady = [
  {
    item_uuid: uuid(),
    title: "Americká limonáda",
    descr: "čerstvá pomarančová šťava, citrónová šťava, trstinový cukor, bazový sirup, mäta, sóda, ľad",
    volume: "0,4 l / 1,0 l",
    price: "3,90 € / 7,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Citronáda",
    descr: "limetková šťava, sóda, cukor, plátok citróna, ľad",
    volume: "0,4 l / 1,0 l",
    price: "2,90 € / 5,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Baza - Yuzu",
    descr: "yuzu - pyré, bazový sirup, citrónová štava, mätové listy, ľad, sóda",
    volume: "0,4 l / 1,0 l",
    price: "3,90 € / 7,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Passion fruit - Mango",
    descr: "passion fruit - pyré, mango - pyré, citrónová šťava, sóda, ľad",
    volume: "0,4 l / 1,0 l",
    price: "3,90 € / 7,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Jahoda - Mäta",
    descr: "jahoda - pyré, mäta, citrónová šťava, sóda, ľad",
    volume: "0,4 l / 1,0 l",
    price: "3,90 € / 7,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Čučoriedka - levanduľa",
    descr: "čučoriedka - pyré, levanduľový sirup, citrónová šťava, sóda, ľad",
    volume: "0,4 l / 1,0 l",
    price: "3,90 € / 7,70 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]