import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const vino = [
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Odporúčame zaujímavý výber vín z vínnej karty !",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Nalievané víno",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Biele víno",
    descr: "",
    volume: "0,10 l",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Červené víno",
    descr: "",
    volume: "0,10 l",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Prosecco",
    descr: "",
    volume: "0,10 l",
    price: "1,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: null,
    iconSize: "45px",
    item_uuid: uuid(),
    title: "Šumivé víno",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
    lastElement: false
  },
  {
    item_uuid: uuid(),
    title: "Hubert de luxe",
    descr: "",
    volume: "0,75 l",
    price: "14,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Moët & Chandon Impérial Brut",
    descr: " ",
    volume: "0,75 l",
    price: "74,50 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]