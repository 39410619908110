import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const pochutiny = [
  {
    item_uuid: uuid(),
    title: "Slané tyčinky",
    descr: "",
    volume: "45 g",
    price: "1,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Slané arašidy",
    descr: "",
    volume: "100 g",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Chips",
    descr: "podľa ponuky",
    volume: "60 g",
    price: "2,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pistácie",
    descr: "",
    volume: "80 g",
    price: "2,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]