import uuid from "react-uuid"
/* import { FaInfoCircle } from "react-icons/fa"; */

export const party = [
  /* {
    type: "extra",
    icon: FaInfoCircle,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Ďalšia akcia už čoskoro !",
    descr: "",
    volume: "",
    price: "",
    noDescr: false,
    divideAfter: false,
    lastElement: true
  }, */
  /* {
    type: "image",
    item_uuid: uuid(),
    offerImg: require("../../../assets/images/clients/offer/MercyCoffee/halloween_event.jpg"),
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: false
  }, */
  {
    type: "image",
    item_uuid: uuid(),
    offerImg: require("../../../assets/images/clients/offer/MercyCoffee/procecco_event.jpg"),
    descr: "",
    volume: "",
    price: "",
    divideAfter: true,
    lastElement: true
  },
]