import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"

export const nealko = [
  {
    item_uuid: uuid(),
    title: "Mattoni",
    descr: "neperlivá / jemne perlivá / perlivá",
    volume: "0,33 l",
    price: "2,00 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pepsi cola",
    descr: "",
    volume: "0,25 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Pepsi cola light",
    descr: "",
    volume: "0,25 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mirinda",
    descr: "",
    volume: "0,25 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "7 up",
    descr: "",
    volume: "0,25 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Canada dry",
    descr: "",
    volume: "0,25 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Lipton ice tea",
    descr: "lemon / peach / green tea",
    volume: "0,25 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Toma",
    descr: "orange / apple / multivitamín / blackcurrant / strawberry / grapefruit / peach / pear",
    volume: "0,25 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Vinea",
    descr: "biela / červená",
    volume: "0,25 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Redbull",
    descr: "",
    volume: "0,25 l",
    price: "2,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Schweppes",
    descr: "indian tonic / ginger ale / bitter lemon",
    volume: "0,25 l",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Mattoni Cedrata",
    descr: "",
    volume: "0,25 l",
    price: "2,20 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: false
  },
]