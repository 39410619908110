import uuid from "react-uuid"
import { BsCreditCard2BackFill } from "react-icons/bs"
/* import new_flag from "../../../assets/images/clients/icons/panorama/new_flag.png" */
/* import {
  GiIceCubes
} from "react-icons/gi" */
export const kava = [
  {
    item_uuid: uuid(),
    title: "Espresso",
    descr: "",
    volume: "8 g",
    price: "1,90 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso Lungo",
    descr: "",
    volume: "8 g",
    price: "1,80 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso Doppio",
    descr: "",
    volume: "16 g",
    price: "3,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Espresso Macchiato",
    descr: "",
    volume: "8g",
    price: "2,10 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Cappuccino",
    descr: "",
    volume: "8 g",
    price: "2,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Latté Macchiato",
    descr: "",
    volume: "8 g",
    price: "2,50 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Flat White",
    descr: "",
    volume: "16 g",
    price: "3,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Viedenská káva",
    descr: "",
    volume: "8 g",
    price: "2,20 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Zalievaná káva",
    descr: "",
    volume: "8 g",
    price: "1,90 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon:/*  GiIceCubes */ null,
    iconSize: "35px",
    iconColor: "white",
    item_uuid: uuid(),
    title: "Ľadová káva",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: true,
  },
  {
    item_uuid: uuid(),
    title: "Espresso Tonic",
    descr: "",
    volume: "8 g",
    price: "2,70 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Frappé",
    descr: "",
    volume: "8 g",
    price: "2,60 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Ľadové Latté",
    descr: "",
    volume: "8 g",
    price: "2,60 €",
    divideAfter: true
  },
  {
    item_uuid: uuid(),
    title: "Marresi / mlieko / bezlaktózové mlieko",
    descr: "",
    volume: "0,02 l",
    price: "0,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Sirup",
    descr: "/&nbsp;čokoláda, karamel, vanilka, kokos&nbsp;/",
    volume: "0,02 l",
    price: "0,30 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Šľahačka",
    descr: "",
    volume: "",
    price: "0,40 €",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Bezkofeínová verzia",
    descr: "všetky kávy",
    volume: "",
    price: "bez príplatku",
    divideAfter: false
  },
  {
    item_uuid: uuid(),
    title: "Bezlaktózová verzia",
    descr: "všetky kávy",
    volume: "",
    price: "+0,30 €",
    divideAfter: true
  },
  {
    type: "extra",
    icon: BsCreditCard2BackFill,
    iconSize: "25px",
    item_uuid: uuid(),
    title: "Možná platba kartou",
    descr: "",
    volume: "",
    price: "",
    noDescr: true,
    divideAfter: false,
    lastElement: true
  },
]