
import { useEffect } from "react"
import Blog from "../components/pages/Blog"


const BlogPage = () => {

  useEffect(() => {
    document.title = `MojeQr - Blog`
    document.getElementsByTagName('meta')[3].content = `V prípade záujmu nás neváhaj kontaktovať na +421 940 850 530 alebo emailom na info@mojeqr.sk. Čakáme na teba!`
    document.getElementsByTagName('link')[2].href = "https://www.mojeqr.sk/kontakt"
  },[])

  return (
    <Blog />
  )
}

export default BlogPage

